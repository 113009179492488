
import { defineComponent } from 'vue';
import { IonTabs, IonTabBar, IonTabButton, IonLabel, IonIcon, IonPage, IonContent, IonRouterOutlet } from '@ionic/vue';
import { personAddOutline, radioOutline, restaurantOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'EinkaufenTabs',
  components: {
    IonLabel,
    IonTabBar,
    IonContent,
    IonTabs,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
  },
  data() {
    return {
      personAddOutline,
      radioOutline,
      restaurantOutline,
    };
  },
});
